import React from 'react';

import DashboardWrapper from '@components/layout/dashboardWrapper/DashboardWrapper';
import CustomErrorPage from '@components/common/customErrorPage/CustomErrorPage';

const ErrorPage = (): JSX.Element => {
  return (
    <DashboardWrapper>
      <CustomErrorPage errorCode={404} />
    </DashboardWrapper>
  );
};

export default ErrorPage;
